import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AppConfig } from '../app.config';



export const UserData = [
  {
    "name": "Applicant",
    "userActivities": [
      { isAllowToSeeApplication: true },
      { isAllowToSeeMyProjects: true },
      { isAllowToSeeAllProjects: false },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeCertificate: true },
      { isAllowToSeeReport: false },

    ]
  },
  {
    "name": "Admin",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeTransferedCertificate: true },
      { isAllowToAssignedApplication: true },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: true },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeCertificate: false },
      { isAllowToSeeReport: true },
    ]
  },
  {
    "name": "Engineer",
    "userActivities": [
      { isAllowToSeeApplication: true },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: true },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: false },
    ]
  },
  {
    "name": "Architect",
    "userActivities": [
      { isAllowToSeeApplication: true },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: false },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: true },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: false },
    ]
  },
  {
    "name": "Staff",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: false },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: true },
    ]
  },
  {
    "name": "Director",
    "userActivities": [
      { isAllowToSeeApplication: false },
      { isAllowToSeeMyProjects: false },
      { isAllowToSeeAllProjects: true },
      { isAllowToSeeAllApplication: true },
      { isAllowToSeeTransferedCertificate: false },
      { isAllowToAssignedApplication: false },
      { isAllowToSeeDashboard: true },
      { isAllowToSeeAdministration: false },
      { isAllowToSeeNotification: true },
      { isAllowToSeeInvoice: true },
      { isAllowToSeeMyPlotDashboard: true },
      { isAllowToSeeReport: false },
      { isAllowToSeeCertificate: true },
    ]
  }



]














@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private appConfig: AppConfig,
    private router: Router
  ) { }

  getSession() {
    return JSON.parse(localStorage.getItem(this.appConfig.JWT_Token) as any);
  }


  getTokenData() {
    return (jwtDecode(this.getSession().data.token.access_token) as any);
  }



  logout() {
    localStorage.removeItem(this.appConfig.JWT_Token);
    localStorage.removeItem(this.appConfig.UPI_NEW_INFO);
    localStorage.removeItem(this.appConfig.Session_User);
    localStorage.removeItem('application');
    this.router.navigate(['/']);
  }

  getPermission() {
    // return JSON.parse(localStorage.getItem(this.appConfig.Session_User) as any);
  }
}
