import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'app-other-application-permit-answer',
  templateUrl: './other-application-permit-answer.component.html',
  styleUrls: ['./other-application-permit-answer.component.scss']
})
export class OtherApplicationPermitAnswerComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  isReadyToSubmit!: boolean;
  submitted!: boolean;
  userForm!: UntypedFormGroup;
  // refurbrishmentForm!: UntypedFormGroup;
  reservedDocumentRequired: any[] = [];
  deleteData: any = {};
  uploadedDocuments: any[] = [];

  documentRequired: any[] = [];
  fileSubmit: any;
  fileData: any = {};


  pageData: any = {};

  yesOrNo: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
    { id: 'Not applicable', name: 'Not applicable' }
  ];

  equipmentCapacities = [
    { id: 'Rain water harvesting', name: 'Rain water harvesting' },
    { id: 'Sewage treatment plant', name: 'Sewage treatment plant' },
    { id: 'Storm water drainages along adjacent plot', name: 'Storm water drainages along adjacent plot' },
  ];

  constructionMethods: any = [
    { id: 'Wall', name: 'Wall' },
    { id: 'Roof', name: 'Roof' },
    { id: 'Foundation', name: 'Foundation' },
    { id: 'Structural elements', name: 'Structural elements' },
  ]






  constructor(
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private utilService: UtilService,
    private formBuilder: UntypedFormBuilder
  ) { }





  ngOnInit() {
    console.log(this.inputData);
    this.pageData.permitType = this.inputData.permitTypes.find((x: any) => x.id === this.inputData.userForm.permitTypeId);
    console.log(this.pageData);
    // *Refurbrishment
    this.userForm = this.formBuilder.group({
      doYouHaveTheOccupancy: [''],
      isFastAidBox: [''],
      disabilityToiletsFlipUpGrabBars: [''],
      paraLighteningSystem: [''],
      equipmentCapacity: [''],
      constructionMethod: [''],
      fireAlarmSystemWithAnAlarmBellOnEach: [''],
      whyNotFireAlarmSystemWithAnAlarmBellOnEach: [''],
      fireExtinguishersEvery50mOnEachFloor: [''],
      whyNotFireExtinguishersEvery50mOnEachFloor: [''],
      functioningExitSignsOnEachFloor: [''],
      whyNotfunctioningExitSignsOnEachFloor: [''],

      anEmergencyExitOnEachFloor: [''],
      whyNotanEmergencyExitOnEachFloor: [''],
      floorPlanOnEachLevel: [''],
      whyNotfloorPlanOnEachLevel: [''],
      numberSignOnEachFloor: [''],
      whyNotnumberSignOnEachFloor: [''],
      signForbiddingTheUseOfElevatorsInCaseOfFire: [''],
      whyNotsignForbiddingTheUseOfElevatorsInCaseOfFire: [''],
      landingSpaceOnTopOfTheBuildingForHelicopters: [''],
      whyNotlandingSpaceOnTopOfTheBuildingForHelicopters: [''],
      CCTVCameras: [''],
      whyNotCCTVCameras: [''],
      WalkThroughAndHeldMetalDetect: [''],
      whyNotWalkThroughAndHeldMetalDetect: [''],
      UnderSearchMirror: [''],
      whyNotUnderSearchMirror: [''],
      LuggageScanners: [''],
      whyNotLuggageScanners: [''],
      PlatesIndicatingEmergencyResponseUnitsPhoneNumbers: [''],
      whyNotPlatesIndicatingEmergencyResponseUnitsPhoneNumbers: [''],
      EmergencyEvacuationPlan: [''],
      whyNotEmergencyEvacuationPlan: [''],
      SecurityManagerAndStaffCameras: [''],
      whyNotSecurityManagerAndStaffCameras: [''],
      AnInternalCommunicationSystem: [''],
      whyNotAnInternalCommunicationSystem: [''],
      BroadBandInternetServices: [''],
      whyNotBroadBandInternetServices: [''],
      StaffAndVisitorAccessCards: [''],
      whyNotStaffAndVisitorAccessCards: [''],
      applicationForFixedTelephoneLineConnection: [''],
      areThereAnyFacilitiesForTheDisabledProvidedBuilding: [''],
      whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding: [''],

      stageOfConstruction: [''],
      supervisingFirmSiteEngineer: [''],
      remarks: [''],
      dateForRequestedInspection: ['']


    })
    // this.userForm = this.formBuilder.group({

    // })
  }



  focusOutFunction() {
    this.backToParent.emit(this.userForm.value);
  }


  close() {

  }



  loadUploadedDocuments() {
    if (this.inputData.applicationSaved) {
      this.applicationService.findAllWithPath(environment.documentUrl + 'DocMgt/' + this.inputData.applicationSaved.id)
        .subscribe(
          documents => {
            this.uploadedDocuments = documents;
            if (this.uploadedDocuments.length === this.reservedDocumentRequired.length) {
              this.isReadyToSubmit = true;
            }
            this.documentRequired = this.reservedDocumentRequired;
            this.documentRequired.forEach((document: any) => {
              let findElement = this.uploadedDocuments.find((x: any) => x.requiredDocumentId === document.id);
              if (findElement) {
                document.uploaded = findElement
              }
            });
          }
        )
    }

  }






  onSubmit(form: any) {

  }



  getFileDetails(e: any, docInfo: any) {
    // this.fileData = this.utilService.getFileDetails(e);
    if (docInfo.name === 'Bill of quantities(BOQ)') {
      if (this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
        this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Please upload BOQ (Bill of Quantities file)', "bottom", "center");

      } else {
        this.fileData.fileName = e.target?.files[0]?.name;
        this.fileData.fileNameDisplay = e.target?.files[0]?.name;
        this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
        this.handleFileSelected(e);
        this.fileData.myFiles = [];
        for (var i = 0; i < e.target.files.length; i++) {
          this.fileData.myFiles.push(e.target.files[i]);
        }
        this.fileData.file = e.target.files;
        this.onSubmitFile();
      }
    } else {
      this.fileData.fileName = e.target?.files[0]?.name;
      this.fileData.fileNameDisplay = e.target?.files[0]?.name;
      this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
      this.handleFileSelected(e);
      if (this.fileData.fileType !== 'excel' && this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
        this.fileData.myFiles = [];
        for (var i = 0; i < e.target.files.length; i++) {
          this.fileData.myFiles.push(e.target.files[i]);
        }
        if (this.fileData.size > 10) {
          this.utilService.showNotification(
            "snackbar-danger",
            "Error: " + 'File will not be saved because it is too big, try 3MB of size',
            "top",
            "end"
          );
        } else {
          this.fileData.file = e.target.files;
          this.onSubmitFile();
        }
      } else {
        this.utilService.showNotification(
          "snackbar-danger",
          "Error: " + 'PDF File allowed only',
          "top",
          "end"
        );
      }
    }
    // this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
    // this.onSubmitFile();
  }


  async handleFileSelected(event: any) {
    const size = event.srcElement.files[0].size;
    if (size < 1000 * 1000 * 1000) {
      this.fileData.size = size / 1000 / 1000;
      this.fileData.unit = "mb";
    }
  }


  onSubmitFile() {
    // if (fileDat) {
    let formData = new FormData();
    const fileList: FileList = this.fileData.file;
    if (fileList && fileList[0]) {
      const file: File = fileList[0];
      this.fileData.fileNameDisplay = file.name
      formData.append('file', file, file.name);
    }
    formData.append('requiredDocumentId', this.userForm.value.documentRequiredId);
    formData.append('applicationId', this.inputData.applicationSaved.id);
    // formData.append('applicationId', this.userForm.value.applicationId);
    this.fileSubmit = true;
    this.applicationService.saveAssetWithPathFormData(formData, environment.documentUrl + 'DocMgt/upload')
      .subscribe(
        data => {
          // this.utilService.showNotification(NOTIFICATION_COLOR.success, "Your application saved successfully", "bottom", "center");
          this.loadUploadedDocuments();
          this.fileSubmit = false;
        }, error => {
          this.fileSubmit = false;
        }
      )
    // } else {
    //   this.submitted = false;
    //   this.utilService.showNotification(NOTIFICATION_COLOR.error, "File is not provided", "bottom", "center");
    // }

  }



}
