import { Component, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ngxCsv } from 'ngx-csv';
import { NgbdAdvancedSortableHeader } from 'src/app/shared/directives/sortable.directive';
import { APIURLPATH } from 'src/app/shared/services/url-path';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { UserMgtService } from 'src/app/user-management/services/user-mgt.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-application-permit-question',
  templateUrl: './application-permit-question.component.html',
  styleUrls: ['./application-permit-question.component.scss']
})
export class ApplicationPermitQuestionComponent {
  userForm!: UntypedFormGroup;
  submitted = false;
  lists: any[] = [

  ];
  total!: number;
  @ViewChildren(NgbdAdvancedSortableHeader)
  headers!: QueryList<NgbdAdvancedSortableHeader>;
  page = 1;
  pageSize = 20;
  startIndex = 0;
  endIndex = 9;
  totalRecords = 0;
  collectionSize = 0;
  content?: any;
  econtent: any = {};
  masterSelected!: boolean;
  permitTypes: any[] = [];


  constructor(
    private modalService: NgbModal,
    public userService: UserMgtService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
  ) {
    this.loadList();
    this.lookups();
  }


  lookups() {
    this.userService.findAllWithPath(environment.applicationUrl + APIURLPATH.PERMITYPES)
      .subscribe(
        data => { this.permitTypes = data; },
        error => { }
      )
  }


  loadList() {
    this.userService
      .findAllWithPath(environment.applicationUrl + APIURLPATH.PERMIT_TYPE_QUESTIONS)
      .subscribe((data) => {
        this.lists = data;
        this.content = data;
        setTimeout(() => {
          document.getElementById("elmLoader")?.classList.add("d-none");
        }, 1200);
        this.collectionSize = this.lists.length;
        this.total = this.lists.length;
        this.totalRecords = this.lists.length;
        this.startIndex = (this.page - 1) * this.pageSize + 1;
        this.endIndex = (this.page - 1) * this.pageSize + this.pageSize;
        if (this.endIndex > this.totalRecords) {
          this.endIndex = this.totalRecords;
        }
        this.lists = this.lists.slice(
          this.startIndex - 1,
          this.endIndex
        );
      });
  }


  ngOnInit(): void {
    this.initiliazeForm();
  }

  initiliazeForm() {
    this.userForm = this.formBuilder.group({
      id: [""],
      description: ['', [Validators.required,]],
      code: ['', [Validators.required]],
      permitTypeId: ['', [Validators.required,]],
      userId: [''],
    });
  }


  openModal(content: any) {
    this.initiliazeForm();
    this.submitted = false;
    this.modalService.open(content, { size: "lg", centered: true });
  }

  checkedValGet: any[] = [];
  onCheckboxChange(e: any) {
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }
    this.checkedValGet = checkedVal;
    checkedVal.length > 0
      ? ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "none");
  }

  getPremiumData() {
    this.lists = this.content
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  editDataGet(id: any, content: any) {
    this.submitted = false;
    this.modalService.open(content, { size: "lg", centered: true });
    var modelTitle = document.querySelector(".modal-title") as HTMLAreaElement;
    modelTitle.innerHTML = "Edit Permit Question";
    // var updateBtn = document.getElementById("add-btn") as HTMLAreaElement;
    // updateBtn.innerHTML = "Update";

    this.userService.findOneWithPath(id, environment.applicationUrl + APIURLPATH.PERMIT_TYPE_QUESTIONS).subscribe({
      next: (data: any) => {
        const users = data.data;
        this.econtent = users;
        this.userForm.controls['code'].setValue(this.econtent.firstName);
        this.userForm.controls['description'].setValue(this.econtent.lastName);
        this.userForm.controls['permitTypeId'].setValue(this.econtent.email);
        this.userForm.controls['userId'].setValue(this.econtent.role.id);
        this.userForm.controls["id"].setValue(this.econtent.id);
        // this.outputData.type = 'editStudent';
        // this.outputData.userForm = this.userForm;
        // this.outputData.isOpen = true;

      },
    });
  }


  deleteData(id: any) {
    if (id) {
      this.userService.deleteWithPath(id, environment.applicationUrl + APIURLPATH.PERMIT_TYPE_QUESTIONS).subscribe({
        next: data => { this.loadList(); },
        error: err => {
        }
      });

    } else {

    }
  }

  deleteMultiple(content: any) {
    var checkboxes: any = document.getElementsByName("checkAll");
    var result;
    var checkedVal: any[] = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        result = checkboxes[i].value;
        checkedVal.push(result);
      }
    }
    if (checkedVal.length > 0) {
      this.modalService.open(content, { centered: true });
    } else {
      Swal.fire({
        text: "Please select at least one checkbox",
        confirmButtonColor: "#239eba",
      });
    }
    this.checkedValGet = checkedVal;
  }

  checkUncheckAll(ev: any) {
    this.lists.forEach((x: { state: any }) => (x.state = ev.target.checked));
    var checkedVal: any[] = [];
    var result;
    for (var i = 0; i < this.lists.length; i++) {
      if (this.lists[i].state == true) {
        result = this.lists[i];
        checkedVal.push(result);
      }
    }
    this.checkedValGet = checkedVal;
    checkedVal.length > 0
      ? ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "block")
      : ((
        document.getElementById("remove-actions") as HTMLElement
      ).style.display = "none");
  }



  onSubmit() {
    if (this.userForm.get("id")?.value) {
      this.submitted = true;
      this.userService.patchAssetWithPath(this.userForm.value.id, this.userForm.value, environment.applicationUrl + APIURLPATH.PERMIT_TYPE_QUESTIONS)
        .subscribe(
          data => {
            this.submitted = false;
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Permit question saved successfully", "bottom", "center");
            this.loadList();
            this.modalService.dismissAll();
          }, error => {
            this.submitted = false;
          }
        )
    } else {
      this.submitted = true;
      this.userService.saveAssetWithPath(this.userForm.value, environment.applicationUrl + APIURLPATH.PERMIT_TYPE_QUESTIONS)
        .subscribe(
          data => {
            this.submitted = false;
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "permit question saved successfully", "bottom", "center");
            this.loadList();
            this.modalService.dismissAll();
          }, error => {
            this.submitted = false;
          }
        )
    }
  }




  // Csv File Export
  csvFileExport() {
    var users = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: true,
      title: "User Data",
      useBom: true,
      noDownload: false,
      headers: [
        "ID",
        "description ",
        "code",
        "permitType",
      ],
    };
    new ngxCsv(this.content, "permit_question", users);
  }

}
