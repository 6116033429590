<div class="step-panel">
    <form [formGroup]="userForm" (ngSubmit)="EIAChecker()">
        <div class="step-panel_header">
            <h2>Check EIA certificate or <a (click)="openEIAclick()">apply here</a></h2>
            <h4 style="color: red;">{{userForm.value.message ? userForm.value.message : ''}}</h4>
        </div>
        <div class="step-panel_body">
            <div class="kbk-x">
                <div class="form-set">
                    <div class="form-input">
                        <label>Certificate Number</label>
                        <div>
                            <input type="text" id="certificateNumber" name="certificateNumber"
                                formControlName="certificateNumber" readonly required>
                        </div>
                    </div>
                    <div class="form-input">
                        <label>UPI</label>
                        <div>
                            <input type="text" id="upi" name="upi"
                                formControlName="upi" readonly>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="step-panel_footer">
            <button type="button" *ngIf="submitted"> Wait... </button>
            <button type="submit" *ngIf="!submitted"> Verify certificate </button>
        </div> -->
    </form>
</div>