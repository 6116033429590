import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from 'ag-grid-angular';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChangePasswordComponent } from './account-layout/components/change-password/change-password.component';
import { EditProfileComponent } from './account-layout/components/edit-profile/edit-profile.component';
import { AgGridTableComponent } from './ag-grid-management/components/ag-grid-table/ag-grid-table.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ApplicationPermitQuestionComponent } from './application/components/application-permit-question/application-permit-question.component';
import { ContinuePendingApplicationComponent } from './application/components/continue-application/continue-pending-application/continue-pending-application.component';
import { ContinuePendingAttachementComponent } from './application/components/continue-application/continue-pending-attachement/continue-pending-attachement.component';
import { NewInfoApplicationComponent } from './application/components/new-application/new-info-application/new-info-application.component';
import { ApplicationApprovalComponent } from './approval-management/application-approval/application-approval.component';
import { VerifiyApplicationComponent } from './auth-pages/components/verifiy-application/verifiy-application.component';
import { VerifyEmailComponent } from './auth-pages/components/verify-email/verify-email.component';
import { CertificateReportComponent } from './reporting-management/components/certificate-report/certificate-report.component';
import { InvoiceReportComponent } from './reporting-management/components/invoice-report/invoice-report.component';
import { VerificationPageComponent } from './shared/components/verification-page/verification-page.component';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { SharedModule } from './shared/modules/shared/shared.module';
import { BuildingTypeComponent } from './system-setting/building-type/building-type.component';
import { PermitTypeComponent } from './system-setting/permit-type/permit-type.component';
// import { AgCharts } from 'ag-charts-angular';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    VerificationPageComponent,
    AgGridTableComponent,
    VerifiyApplicationComponent,
    VerifyEmailComponent,
    BuildingTypeComponent,
    PermitTypeComponent,
    ApplicationApprovalComponent,
    ChangePasswordComponent,
    InvoiceReportComponent,
    CertificateReportComponent,
    ApplicationPermitQuestionComponent,
    ContinuePendingApplicationComponent,
    ContinuePendingAttachementComponent,
    NewInfoApplicationComponent,
    EditProfileComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AgGridModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    HttpClientModule,
    AgChartsAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AppConfig
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
