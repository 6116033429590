import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { CrudService } from 'src/app/core/generic-service/crud-service';
import { HeaderOptions } from 'src/app/core/generic-service/header-options/header-options';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CrudService<any, any> {

  constructor(
    protected httpClient: HttpClient,
    protected _headerOption: HeaderOptions,
    private appConfig: AppConfig
  ) {
    super(httpClient, '', _headerOption);
  }



  getCurrentUser() {
    let data = JSON.parse(localStorage.getItem(this.appConfig.Session_User) as any); 
  }
}
