import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgbAccordionModule, NgbDropdownModule, NgbModule, NgbNavModule, } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { HighchartsChartModule } from 'highcharts-angular';
import { EiaFormCheckerComponent } from 'src/app/application/components/eia-form-checker/eia-form-checker.component';
import { OtherApplicationPermitAnswerComponent } from 'src/app/application/components/other-application-permit-answer/other-application-permit-answer.component';
import { GenericApprovalComponent } from 'src/app/approval-management/generic-approval/generic-approval.component';
import { UpiInfoComponent } from 'src/app/auth-pages/components/upi-info/upi-info.component';
import { AssignToEngineerFormComponent } from '../../../application/components/assignment-to-eng-architect/assign-to-engineer-form/assign-to-engineer-form.component';
import { InputFileUploadComponent } from '../../components/input-file-upload/input-file-upload.component';
import { OneFieldFormComponent } from '../../components/one-field-form/one-field-form.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { ViewDocumentComponent } from '../../components/view-document/view-document.component';
import { ViewPlotOnMapComponent } from '../../components/view-plot-on-map/view-plot-on-map.component';
import { NgbdAdvancedSortableHeader } from '../../directives/sortable.directive';
import { FilterPipe } from '../../pipes/generic-filter.pipe';
import { AgCharts } from 'ag-charts-enterprise';
import { AgChartsAngularModule } from 'ag-charts-angular';


@NgModule({
  declarations: [
    InputFileUploadComponent,
    NgbdAdvancedSortableHeader,
    UpiInfoComponent,
    FilterPipe,
    OneFieldFormComponent,
    ViewDocumentComponent,
    GenericApprovalComponent,
    EiaFormCheckerComponent,
    AssignToEngineerFormComponent,
    SpinnerComponent,
    ViewPlotOnMapComponent,
    OtherApplicationPermitAnswerComponent,
    
  ],
  imports: [
    ReactiveFormsModule,
    // AgChartsAngularModule,
    AgGridModule,
    CommonModule,
    GoogleMapsModule,
    MatSnackBarModule,
    MatFormFieldModule,
    NgbNavModule,
    TranslateModule,
    FormsModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbModule,
    
  ],
  exports: [
    CommonModule,
    GoogleMapsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    HighchartsChartModule,
    FormsModule,
    MatSnackBarModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule,
    AgChartsAngularModule,
    NgbModule,
    NgbdAdvancedSortableHeader,
    AgGridModule,
    InputFileUploadComponent,
    FilterPipe,
    UpiInfoComponent,
    OneFieldFormComponent,
    ViewDocumentComponent,
    GenericApprovalComponent,
    EiaFormCheckerComponent,
    AssignToEngineerFormComponent,
    SpinnerComponent,
    ViewPlotOnMapComponent,
    OtherApplicationPermitAnswerComponent
  ]
})
export class SharedModule { }
