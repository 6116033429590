// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // authUrl: 'http://localhost:3001/',
  authUrl: 'http://197.243.12.45:3001/',
  applicationUrl: 'http://197.243.12.45:3000/',
  documentUrl: 'http://197.243.12.45:3005/',
  integrationUrl: 'http://197.243.12.45:3007/',
  nidaUrl: 'http://197.243.12.45:3007/integration/nida?documentNumber=',
  iremboPayKey: 'pk_live_006583a232684ae2979cb7ba91ea6e5e',
  // iremboPayKey: 'pk_live_9893b7756019459c9c2801b774038d7b',
  eia: 'http://testing.kubaka.gov.rw/testing-api/verify/eia'
  // eia: 'https://testing.kubaka.gov.rw/testing-api/verify/eia'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
