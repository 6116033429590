<div class="app-gen">
    <div class="app-gen-header">
        <div class="genline">
            <div>
                <!-- <span class="hder" aria-label="header tittle"> Certificate Verification</span> -->
            </div>
            <div class="btns">
                <button type="button" class="kbk-btn kbk-btn-outline"
                    (click)="changeCertificate('k')">Kinyarwanda</button>
                <button type="button" class="kbk-btn kbk-btn-outline" (click)="changeCertificate('f')">French</button>
                <button type="button" class="kbk-btn kbk-btn-outline" (click)="changeCertificate('e')">English</button>
            </div>
        </div>
    </div>
    <div class="app-gen-content">
        <!-- ====CERTIFICATE LANSCAPE PAPER ==== -->
        <!-- english default -->
        <div class="pagelandscape" [ngClass]="{'notvalid': isExpired,
          'valid' : !isExpired }" id='pdf' *ngIf="cert === 'e'">
            <div class="pagesheet">
                <section class="frontpg">
                    <img class="bgimg" src="assets/imgs/kcls-bd-1.png" alt="">
                    <div class="page-header" style="display: none">
                        <div class="main">
                            <div class="page-header_coa">
                                <img src="assets/imgs/CofA.png" alt="" />
                            </div>
                            <div class="sep"></div>
                            <div class="page-header_title">
                                <h1>Republic of Rwanda</h1>
                                <h2>{{certificateDetail?.agencyName}}</h2>
                            </div>
                            <div class="page-header_address">
                                <p class="addr"><span>Province:</span> City of Kigali</p>
                                <p class="addr"><span>District:</span> Gasabo</p>
                                <p class="addr">
                                    <span>P.O BOX:</span> 7066, Gasabo
                                </p>
                            </div>
                        </div>
                        <div class="border">
                            <span class="border-b"></span>
                            <span class="border-y"></span>
                            <span class="border-g"></span>
                        </div>
                    </div>
                    <div class="page-header">
                        <div class="main">
                            <div class="page-header_coa">
                                <img src="assets/imgs/CofA.png" alt="" />
                            </div>
                            <div class="page-header_title centered">
                                <h1>Republic of Rwanda</h1>
                                <h2>{{certificateDetail?.agencyName}}</h2>
                            </div>
                            <div class="page-header_coa">
                                <img src="assets/imgs/city.png" alt="" />
                            </div>
                        </div>
                        <div class="border">
                            <span class="border-b"></span>
                            <span class="border-y"></span>
                            <span class="border-g"></span>
                        </div>
                    </div>
                    <div class="page-body">
                        <div class="page-body_title">
                            <h3>CONSTRUCTION BUILDING PERMIT</h3>
                        </div>
                        <div class="page-body_content">
                            <p>
                                <span>Based on:</span> Law No. 20/2011 of 21/06/2011 determines the organization of
                                housing in Rwanda, Law No. 10/2012 of 02/05/2012 that determines the organization of
                                cities and buildings in Rwanda Ministerial Order N° 02/CAB.M/019 of 15/04/2019
                                determines the categories of buildings and methods applicable to the application and
                                issuance of building permits.
                            </p>
                            <article>
                                <h4>THE LIST</h4>
                                <ul>
                                    <li> Name of business owner: <strong> {{
                                            certificateDetail?.applications?.userDetails?.lastName | uppercase }} {{
                                            certificateDetail?.applications?.userDetails?.firstName | uppercase
                                            }}</strong>
                                    </li>
                                    <!-- <li> Name of the project:: <strong>{{ certificateDetail?.permitTypes?.name |
                                            uppercase }}</strong>
                                    </li> -->
                                    <li> National ID: <strong>{{ certificateDetail?.applications?.userDetails?.nationalId }}</strong>
                                    </li>
                                    <li> Land lease agreement: <strong>{{ certificateDetail?.applications?.projects?.upi
                                            }}</strong>
                                    </li>
                                    <li> &nbsp;<span> Location of the Project:</span>
                                        <span class="grp">
                                            <span>Sector:<strong> {{ certificateDetail?.applications?.projects
                                                    ?.sectorName | uppercase }}</strong></span>
                                            <span>Cell:<strong> {{ certificateDetail?.applications?.projects?.cellName |
                                                    uppercase }}</strong></span>
                                            <span>Village:<strong> {{ certificateDetail?.applications?.projects
                                                    ?.villageName | uppercase }}</strong></span>
                                        </span>
                                    </li>
                                    <li> Building permit: <strong>{{ certificateDetail?.certificateNumber | uppercase
                                            }}</strong>
                                    </li>
                                </ul>
                                <p></p>
                            </article>
                            <article>
                                <h4>PROJECT DESCRIPTION</h4>
                                <ul>
                                    <li> Project name: <strong>{{ certificateDetail?.applications?.projects?.projectName
                                    }}</strong>
                            </li>
                                    <li> Intended use: <strong>{{ certificateDetail?.applications?.projects?.selectedUse
                                            }}</strong>
                                    </li>
                                    <li> Built surface: <strong>{{
                                            certificateDetail?.applications?.buildUpArea }}</strong>
                                    </li>
                                    <li> Number of floors: <strong>{{
                                            certificateDetail?.applications?.numberOfFloor }}</strong>
                                    </li>
                                    <li> Project Reference Number: <strong>{{
                                            certificateDetail?.applications?.applicationName }}</strong>
                                    </li>
                                </ul>
                                <p></p>
                            </article>
                        </div>
                    </div>
                    <div class="page-footer">
                        <div class="page-footer_dates">
                            <p> Date Launched: <strong>{{ certificateDetail.created_at | date }}</strong>
                            </p>
                            <p> Expiration Date: <strong>{{ certificateDetail.expiredDate | date }}</strong>
                            </p>
                        </div>
                        <div class="page-footer_done">
                            <p>Approved By:</p>
                            <p><strong>Eng. Umutoni Uwase Clarisse</strong></p>
                            <p><b>Ag.</b> Director of Construction</p>
                            <p>Permitting OSC Unit</p>
                        </div>
                    </div>
                    <div class="qcode">
                        <!-- <img src="assets/imgs/qcod.png" alt=""> -->
                        <qr-code value="http://testing.kubaka.gov.rw/verification/certificate/{{certificateDetail?.id}}"
                            size="100" errorCorrectionLevel="M"></qr-code>
                    </div>
                </section>
                <section class="backpg">
                    <img class="bgimg" src="assets/imgs/kcls-bd-1.png" alt="">
                    <div class="page-body">
                        <div class="page-body">
                            <div class="page-body_title">
                                <h3> THE FOLLOWING ACTIVITIES ARE SUBJECT TO THE JURISDICTION OF THE OWNER AS OUTLINED
                                    IN MINISTERIAL ORDER NO. 03/CAB.M/019 DATED 15/04/2019, WHICH DELINEATES THE
                                    REGULATIONS GOVERNING URBAN ORGANIZATION AND BUILDING STANDARDS DETAILED IN ANNEX
                                    TITLE IV. THIS SECTION ENCOMPASSES CONSTRUCTION DEFECTS AND PENALTIES ASSOCIATED
                                    WITH THE COSTS INCURRED DUE TO DEFECTIVE BUILDINGS. </h3>
                            </div>
                            <div class="page-body_content">
                                <article>
                                    <ol>
                                        <li> The new building that started without a permit, but complied with the
                                            relevant regulations and urban planning and infrastructure. </li>
                                        <li> New construction started without a permit, and without complying with the
                                            relevant regulations and urban planning and infrastructure. </li>
                                        <li> Renovation of the building (modifying or not changing the structure)
                                            started without permission. </li>
                                        <li> Buildings that do not require mandatory foundation inspections. </li>
                                        <li>Use of the building without a license to use it</li>
                                        <li> Changing the building's intended use without permission. </li>
                                        <li>Demolition of buildings without permission.</li>
                                        <li> Building without a licensed architect or an architectural expert. </li>
                                        <li> Construction without a Contractor and a construction supervisor. </li>
                                        <li> Construction conducted after the expiration of the construction permit
                                            without subsequent extension granted. </li>
                                        <li> The building permit that differs from the requirements of land use plans as
                                            well as zoning and urbanization documents. </li>
                                        <li> Construction that threatens the safety and strength of the building.</li>
                                        <li>Violation of approved building plans.</li>
                                        <li> Contraindications to materials and methods related to fire prevention.
                                        </li>
                                        <li>Violation of safety equipment.</li>
                                        <li>The building is not insured.</li>
                                        <li>The building works without insurance.</li>
                                        <li>Violation of building inspectors' regulations.</li>
                                        <li>Disturbing an inspector on duty.</li>
                                        <li> Failure to post a sign posted at a construction site. </li>
                                        <li> Construction works restricting the use of public roads as classified and
                                            other urban planning regulations, or exceed local boundaries without
                                            permission. </li>
                                        <li> Prohibited activities as defined by the City Planning Ordinance in Areas
                                            most affected by non-building activities. </li>
                                    </ol>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div class="page-footer"></div>
                </section>
            </div>
        </div>
        <!-- english default -->
        <!-- kinyarwanda -->
        <div class="pagelandscape" [ngClass]="{'notvalid': isExpired,
        'valid' : !isExpired }" id='pdf' *ngIf="cert === 'k'">
            <div class="pagesheet">
                <section class="frontpg">
                    <img class="bgimg" src="assets/imgs/kcls-bd-1.png" alt="">
                    <div class="page-header" style="display: none">
                        <div class="main">
                            <div class="page-header_coa">
                                <img src="assets/imgs/CofA.png" alt="" />
                            </div>
                            <div class="sep"></div>
                            <div class="page-header_title">
                                <h1>Repubulika y’u Rwanda</h1>
                                <h2>UMUJYI WA KIGALI</h2>
                            </div>
                            <div class="page-header_address">
                                <p class="addr"><span>Intara:</span> City of Kigali</p>
                                <p class="addr"><span>Akarere:</span> Gasabo</p>
                                <p class="addr">
                                    <span>Agasanduku k’iposita:</span> 7066, Gasabo
                                </p>
                            </div>
                        </div>
                        <div class="border">
                            <span class="border-b"></span>
                            <span class="border-y"></span>
                            <span class="border-g"></span>
                        </div>
                    </div>
                    <div class="page-header">
                        <div class="main">
                            <div class="page-header_coa">
                                <img src="assets/imgs/CofA.png" alt="" />
                            </div>
                            <div class="page-header_title centered">
                                <h1>Repubulika y’u Rwanda</h1>
                                <h2>UMUJYI WA KIGALI</h2>
                            </div>
                            <div class="page-header_coa">
                                <img src="assets/imgs/city.png" alt="" />
                            </div>
                        </div>
                        <div class="border">
                            <span class="border-b"></span>
                            <span class="border-y"></span>
                            <span class="border-g"></span>
                        </div>
                    </div>
                    <div class="page-body">
                        <div class="page-body_title">
                            <h3>URUHUSHYA RWO KUBAKA</h3>
                            <!-- <h3>URUHUSHYA RWO GUSANA NO KUVUGURURA BIDAHINDURA IMITERERE</h3> -->
                        </div>
                        <div class="page-body_content">
                            <p>
                                <span>Nshingiye ku:</span> Itegeko No.20/2011 ryo kuwa 21/06/2011 rigena imitunganyirize
                                y’imiturire mu Rwanda, Itegeko No.10/2012 ryo kuwa 02/05/2012 rigena imitunganyirize
                                y’imijyi n’imyubakire mu Rwanda Iteka rya Minisitiri N° 02/CAB.M/019 ryo ku wa
                                15/04/2019 rigena ibyiciro by’inyubako n’uburyo bukurikizwa mu gusaba no gutanga
                                impushya zo kubaka.
                            </p>
                            <article>
                                <h4>UMWIRONDORO</h4>
                                <ul>
                                    <li> Izina rya nyir’umushinga: <strong> {{
                                            certificateDetail?.applications?.userDetails?.lastName | uppercase }} {{
                                            certificateDetail?.applications?.userDetails?.firstName | uppercase
                                            }}</strong>
                                    </li>
                                    <li> Izina ry’umushinga:: <strong>{{ certificateDetail?.permitTypes?.name |
                                            uppercase }}</strong>
                                    </li>
                                    <li> Amasezerano y’ubukode bw’ubutaka: <strong>{{
                                            certificateDetail?.applications?.projects?.upi }}</strong>
                                    </li>
                                    <li> &nbsp;<span> Aho Umushinga uherereye:</span>
                                        <span class="grp">
                                            <span>Umurenge:<strong> {{ certificateDetail?.applications?.projects
                                                    ?.sectorName | uppercase }}</strong></span>
                                            <span>Akagari:<strong> {{
                                                    certificateDetail?.applications?.projects?.cellName | uppercase
                                                    }}</strong></span>
                                            <span>Umudugudu:<strong> {{ certificateDetail?.applications?.projects
                                                    ?.villageName | uppercase }}</strong></span>
                                        </span>
                                    </li>
                                    <li> Uruhushya rwo kubaka: <strong>{{ certificateDetail?.certificateNumber |
                                            uppercase }}</strong>
                                    </li>
                                </ul>
                                <p></p>
                            </article>
                            <article>
                                <h4>UBUSOBANURO BW’UMUSHINGA</h4>
                                <ul>
                                    <li> Icyo igenewe gukoreshwa: <strong>{{
                                            certificateDetail?.applications?.projects?.selectedUse }}</strong>
                                    </li>
                                    <li> Ubuso bwubatsweho: <strong>{{
                                            certificateDetail?.applications?.projects?.buildUpArea }}</strong>
                                    </li>
                                    <li> Umubare w’amagorofa: <strong>{{
                                            certificateDetail?.applications?.projects?.numberOfFloor }}</strong>
                                    </li>
                                    <li> Project Reference Number: <strong>{{
                                            certificateDetail?.applications?.applicationName }}</strong>
                                    </li>
                                </ul>
                                <p></p>
                            </article>
                        </div>
                    </div>
                    <div class="page-footer">
                        <div class="page-footer_dates">
                            <p> Itariki Rutangiweho: <strong>{{ certificateDetail.created_at | date }}</strong>
                            </p>
                            <p> Itariki Ruzarangiriraho: <strong>{{ certificateDetail.expiredDate | date }}</strong>
                            </p>
                        </div>
                        <div class="page-footer_done">
                            <p>Approved By:</p>
                            <p><strong>Eng. Umutoni Uwase Clarisse</strong></p>
                            <p><b>Ag.</b> Director of Construction</p>
                            <p>Permitting OSC Unit</p>
                        </div>
                    </div>
                    <div class="qcode">
                        <!-- <img src="assets/imgs/qcod.png" alt=""> -->
                        <qr-code
                            value="http://testing.kubaka.gov.rw/verification/certificate/verification/certificate/{{certificateDetail?.id}}"
                            size="100" errorCorrectionLevel="M"></qr-code>
                    </div>
                </section>
                <section class="backpg">
                    <img class="bgimg" src="assets/imgs/kcls-bd-1.png" alt="">
                    <div class="page-body">
                        <div class="page-body">
                            <div class="page-body_title">
                                <h3> IBIKORWA BIKURIKIRA BIHANIRWA NYIRABYO NK’UKO BITEGANYWA N’ITEKA RYA MINISITIRI NO
                                    03/CAB.M/019 RYO KU WA 15/04/2019 RISHYIRAHO AMABWIRIZA AJYANYE N’IMITUNGANYIRIZE
                                    Y’IMIJYI N’IMYUBAKIRE MU MUGEREKA WARYO WA IV UKUBIYEMO AMAKOSA Y’IMYUBAKIRE
                                    N’IBIHANO HAKURIKIJWE IBYICIRO BY’INYUBAKO IKOSA RIKOREWEMO. </h3>
                            </div>
                            <div class="page-body_content">
                                <article>
                                    <ol>
                                        <li> Inyubako nshya yatangiye nta ruhushya, ariko yubahirije ibisabwa
                                            n’amabwiriza ajyanye n’imitunganyirize y’imijyi n’imyubakire. </li>
                                        <li> Inyubako nshya yatangiye nta ruhushya, kandi itubahirije ibisabwa
                                            n’amabwiriza ajyanye n’imitunganyirize y’imijyi n’imyubakire. </li>
                                        <!-- <div>
                        <br>
                        <br>
                        <br>
                      </div> -->
                                        <li> Kuvugurura inyubako (bihindura cg bidahindura imiterere) byatangiye
                                            ntaruhushya. </li>
                                        <li> Imyubakire itasabye igenzura ritegetswe rya fondasiyo. </li>
                                        <li>Gukoresha inyubako nta ruhushya rwo kuyikoresha.</li>
                                        <li> Guhindura icyo inyubako yagenewe gukoreshwa nta ruhushya. </li>
                                        <li>Gusenya inyubako nta ruhushya.</li>
                                        <li> Kubaka nta muhanga wemewe mu guhanga inyubako cg impuguke mu by’ubwubatsi.
                                        </li>
                                        <li> Kubaka nta Rwiyemezamirimo ndetse n’umugenzuzi w’imirimo y’ubwubatsi. </li>
                                        <li> Ibikorwa by’ubwubatsi bikozwe uruhushya rwararangije igihe kandi nta rundi
                                            rwongera igihe rwatanzwe </li>
                                        <li> Uruhushya rwo kubaka runyuranyije n’ibiteganywa ku mikoreshereze y’ubutaka
                                            ndetse n’ inyandiko z’imitunganyirize y’icyaro n’imijyi. </li>
                                        <li> Kubaka bibangamiye umutekano ndetse n’ubukomere bw’inyubako. </li>
                                        <li>Kunyuranya n’ibishushanyo byemejwe by’inyubako.</li>
                                        <li> Kunyuranya n’ibikoresho ndetse n'uburyo bijyanye no kwirinda inkongi. </li>
                                        <li>Kunyuranya n’ibikoresho by’umutekano.</li>
                                        <li>Inyubako ikorerwamo nta bwishingizi.</li>
                                        <li>Inyubako ikora nta bwishingizi.</li>
                                        <li>Kunyuranya n’amabwiriza y’abagenzuzi b'inyubako.</li>
                                        <li>Kubangamira umugenzuzi uri mu kazi.</li>
                                        <li> Kudashyiraho icyapa gishyirwa ahakorerwa imirimo y’ubwubatsi. </li>
                                        <li> Imirimo y’ubwubatsi ibuza ikoreshwa ry’imihanda nyabagendwa nk’uko
                                            yashyizwe mu byiciro n’urwunge rw’amabwiriza agenga imitunganyirize
                                            y’imijyi, cyangwa irenga imbibi z’ikibanza utaherewe uruhushya. </li>
                                        <li> Imirimo ibujijwe nk’uko bisobanurwa n’urwunge rw’amabwiriza agenga
                                            imitunganyirize y’imijyi mu duce dukorwaho cyane n’imirimo itariyo kubaka
                                            inzu. </li>
                                    </ol>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div class="page-footer"></div>
                </section>
            </div>
        </div>
        <!-- kinyarwanda -->
        <!-- french -->
        <div class="pagelandscape" [ngClass]="{'notvalid': isExpired,
        'valid' : !isExpired }" id='pdf' *ngIf="cert === 'f'">
            <div class="pagesheet">
                <section class="frontpg">
                    <img class="bgimg" src="assets/imgs/kcls-bd-1.png" alt="">
                    <div class="page-header" style="display: none">
                        <div class="main">
                            <div class="page-header_coa">
                                <img src="assets/imgs/CofA.png" alt="" />
                            </div>
                            <div class="sep"></div>
                            <div class="page-header_title">
                                <h1>République du Rwanda</h1>
                                <h2>VILLE DE KIGALI</h2>
                            </div>
                            <div class="page-header_address">
                                <p class="addr"><span>Province:</span> Ville de Kigali</p>
                                <p class="addr"><span>District:</span> Gasabo</p>
                                <p class="addr">
                                    <span>Boîte postale:</span> 7066, Gasabo
                                </p>
                            </div>
                        </div>
                        <div class="border">
                            <span class="border-b"></span>
                            <span class="border-y"></span>
                            <span class="border-g"></span>
                        </div>
                    </div>
                    <div class="page-header">
                        <div class="main">
                            <div class="page-header_coa">
                                <img src="assets/imgs/CofA.png" alt="" />
                            </div>
                            <div class="page-header_title centered">
                                <h1>République du Rwanda</h1>
                                <h2>VILLE DE KIGALI</h2>
                            </div>
                            <div class="page-header_coa">
                                <img src="assets/imgs/city.png" alt="" />
                            </div>
                        </div>
                        <div class="border">
                            <span class="border-b"></span>
                            <span class="border-y"></span>
                            <span class="border-g"></span>
                        </div>
                    </div>
                    <div class="page-body">
                        <div class="page-body_title">
                            <h3>PERMIS DE CONSTRUIRE</h3>
                        </div>
                        <div class="page-body_content">
                            <p>
                                <span>Basé sur:</span> La loi n°20/2011 du 21/06/2011 détermine l'organisation du
                                logement en Rwanda, Loi n° 10/2012 du 02/05/2012 déterminant l'organisation des villes
                                et des bâtiments au Rwanda L'Arrêté Ministériel N° 02/CAB.M/019 du 15/04/2019 détermine
                                les catégories de bâtiments et les modalités applicable à la demande et à la délivrance
                                des permis de construire.
                            </p>
                            <article>
                                <h4>LA LISTE</h4>
                                <ul>
                                    <li> Nom du propriétaire de l'entreprise: <strong> {{
                                            certificateDetail?.applications?.userDetails?.lastName | uppercase }} {{
                                            certificateDetail?.applications?.userDetails?.firstName | uppercase
                                            }}</strong>
                                    </li>
                                    <li> Nom du projet:: <strong>{{ certificateDetail?.permitTypes?.name | uppercase
                                            }}</strong>
                                    </li>
                                    <li> Contrat de bail foncier: <strong>{{
                                            certificateDetail?.applications?.projects?.upi }}</strong>
                                    </li>
                                    <li> &nbsp;<span> Localisation du projet:</span>
                                        <span class="grp">
                                            <span>Secteur:<strong> {{ certificateDetail?.applications?.projects
                                                    ?.sectorName | uppercase }}</strong></span>
                                            <span>Cellule:<strong> {{
                                                    certificateDetail?.applications?.projects?.cellName | uppercase
                                                    }}</strong></span>
                                            <span>Village:<strong> {{ certificateDetail?.applications?.projects
                                                    ?.villageName | uppercase }}</strong></span>
                                        </span>
                                    </li>
                                    <li>Permis de construire: <strong>{{ certificateDetail?.certificateNumber |
                                            uppercase }}</strong>
                                    </li>
                                </ul>
                                <p></p>
                            </article>
                            <article>
                                <h4>DESCRIPTION DU PROJET</h4>
                                <ul>
                                    <li> Utilisation prévue: <strong>{{
                                            certificateDetail?.applications?.projects?.selectedUse }}</strong>
                                    </li>
                                    <li> Surface bâtie: <strong>{{
                                            certificateDetail?.applications?.projects?.buildUpArea }}</strong>
                                    </li>
                                    <li> Nombre d'étages: <strong>{{
                                            certificateDetail?.applications?.projects?.numberOfFloor }}</strong>
                                    </li>
                                    <li> Numéro de référence du projet: <strong>{{
                                            certificateDetail?.applications?.applicationName }}</strong>
                                    </li>
                                </ul>
                                <p></p>
                            </article>
                        </div>
                    </div>
                    <div class="page-footer">
                        <div class="page-footer_dates">
                            <p> Date de lancement: <strong>{{ certificateDetail.created_at | date }}</strong>
                            </p>
                            <p> Date d'expiration: <strong>{{ certificateDetail.expiredDate | date }}</strong>
                            </p>
                        </div>
                        <div class="page-footer_done">
                            <p>Approuvé par:</p>
                            <p><strong>Eng. Umutoni Uwase Clarisse</strong></p>
                            <p><b>Ag.</b> Directeur du chantier</p>
                            <p>Unité OSC autorisant</p>
                        </div>
                    </div>
                    <div class="qcode">
                        <!-- <img src="assets/imgs/qcod.png" alt=""> -->
                        <qr-code value="http://testing.kubaka.gov.rw/verification/certificate/{{certificateDetail?.id}}"
                            size="100" errorCorrectionLevel="M"></qr-code>
                    </div>
                </section>
                <section class="backpg">
                    <img class="bgimg" src="assets/imgs/kcls-bd-1.png" alt="">
                    <div class="page-body">
                        <div class="page-body">
                            <div class="page-body_title">
                                <h3> LES ACTIVITÉS SUIVANTES SONT SOUMISEES AU PROPRIÉTAIRE COMME PRÉVU PAR L'ARRÊTÉ
                                    MINISTÉRIEL N° 03/CAB.M/019 RYO LE 15/04/2019 INSTALLATION DE L'ORGANISATION URBAINE
                                    ET DES REGLES DE CONSTRUCTION EN ANNEXE TITRE IV DÉFAUTS DE CONSTRUCTION ET
                                    SANCTIONS POUR FRAIS DE CONSTRUCTION DÉFECTUEUX FAIS-LE. </h3>
                            </div>
                            <div class="page-body_content">
                                <article>
                                    <ol>
                                        <li> Le nouveau bâtiment a commencé sans permis, mais était conforme à la
                                            réglementation en vigueur et l’urbanisme et les infrastructures. </li>
                                        <li> Une nouvelle construction a commencé sans permis et sans respecter la
                                            réglementation en vigueur et l’urbanisme et les infrastructures. </li>
                                        <li> La rénovation du bâtiment (modification ou non de la structure) a commencé
                                            sans autorisation. </li>
                                        <li> Bâtiments qui ne nécessitent pas d’inspection obligatoire des fondations.
                                        </li>
                                        <li>Utilisation du bâtiment sans permis d'utilisation</li>
                                        <li> Modification de l'usage prévu du bâtiment sans autorisation. </li>
                                        <li>Démolition de bâtiments sans autorisation.</li>
                                        <li> Un architecte n’est pas un architecte agréé ni un expert en architecture.
                                        </li>
                                        <li> Construction sans entrepreneur ni superviseur de construction. </li>
                                        <li> Le permis de travaux de construction a expiré et il n'y a pas de nouvelle
                                            prolongation a été donné </li>
                                        <li> Le permis de construire est en violation des réglementations et documents
                                            d'utilisation du sol de l'aménagement rural et urbain. </li>
                                        <li> La construction menace la sécurité et la solidité du bâtiment. </li>
                                        <li>Violation des plans de construction approuvés.</li>
                                        <li> Contre-indications aux matériaux et méthodes liés à la prévention des
                                            incendies. </li>
                                        <li>Violation des équipements de sécurité.</li>
                                        <li>Le bâtiment n'est pas assuré.</li>
                                        <li>Le bâtiment fonctionne sans assurance.</li>
                                        <li>Violation des règlements des inspecteurs en bâtiment.</li>
                                        <li>Déranger un inspecteur en service.</li>
                                        <li> Défaut d'afficher une pancarte affichée sur un chantier de construction.
                                        </li>
                                        <li> Travaux de construction restreignant l'usage de la voie publique classée et
                                            d'autres réglementations d'urbanisme, ou dépasser les limites locales sans
                                            autorisation. </li>
                                        <li> Activités interdites telles que définies par l'ordonnance d'urbanisme de
                                            Zones les plus touchées par les activités non constructibles. </li>
                                    </ol>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div class="page-footer"></div>
                </section>
            </div>
        </div>
        <!-- french -->
    </div>
</div>